import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Row, Table } from "antd";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { useTransactionPostRequest } from "../../Hooks/withdrawal/useWithdrawal.query";
import { formatCurrency } from "../../helpers/moneyFormat";

const date = new Date();
const dateExtencil = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

function Withdrawal() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(useTransactionPostRequest, {
    onSettled: () => {
      console.log("onSettled");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("saque");
    },
    onError: () => {}
  });
  const { data, isLoading } = useQuery(["saque"], () =>
    axios
      .get(
        `${process.env.REACT_APP_API}/v1/withdrawal/requested-withdrawal?date=${dateExtencil}&expand=profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        }
      )
      .then((res) => {
        return res.data.data;
      })
  );

  const handleAcceptWithdraw = (data) => {
    let amount = data.value;
    amount = amount.replace("$", "");
    const entry = {
      type: 5,
      amount,
      investor: data.accepted,
      accepted: 1,
      id_withdrawal: data.key
    };
    mutateAsync(entry);
  };
  const columns = [
    {
      title: "",
      dataIndex: "isAccepted",
      key: "isAccepted",
      render: (data) => {
        if (data) {
          return (
            <span alt="Ja repassado" title="Ja repassado">
              <CheckCircleOutlined />
            </span>
          );
        }
        return (
          <span title="Não repassado">
            <CloseCircleOutlined />
          </span>
        );
      },
      width: 5,
      rowScope: "row",
      fixed: "left"
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => (
        <Link to={`/investidor/perfil/${text.id}`}>{text.name}</Link>
      )
      // width: "40%",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value"
      // width: "15%",
    },
    {
      title: "Limite",
      dataIndex: "limit",
      key: "limit"
      // width: "15%",
    },
    // {
    //   title: "Saldo apos Saque",
    //   dataIndex: "after_withdraw",
    //   key: "after_withdraw",
    //   render: (_) => (
    //     <span>{formatCurrency((_.limit - _.amount))}</span>
    //   )
    // },
    {
      title: "Data solicitação",
      dataIndex: "started_at",
      key: "started_at"
      // width: "15%",
    },
    {
      title: "Aceitar solicitação",
      dataIndex: "accepted",
      key: "accepted",
      render: (_, data) => {
        if (data.isAccepted) {
          return <span></span>;
        }
        return (
          <span>
            <Link onClick={() => handleAcceptWithdraw(data)}>Aceitar</Link>
          </span>
        );
      }
      // width: "15%",
    }
  ];
  const dataUser = data?.map((profile, index) => {
    const options = {
      dateStyle: "full" || "long" || "medium" || "short",
      timeStyle: "full" || "long" || "medium" || "short"
    };
    return {
      key: profile?.id,
      id: profile?.profile.id,
      name: profile?.profile.name,
      value: formatCurrency(profile?.amount),
      limit: formatCurrency(profile?.limit),
      after_withdraw: profile,
      started_at: new Date(profile?.created_at * 1000).toLocaleString(
        "pt-br",
        options
      ),
      accepted: profile.user_id,
      isAccepted: profile.accepted
    };
  });

  if (isLoading) {
    return "Carregando...";
  }

  return (
    <div className="layout-content">
      <Row gutter={[24, 5]}>
        {/* <div className="table-responsive"> */}
        <Table
          layout="auto"
          columns={columns}
          dataSource={dataUser}
          pagination={true}
          className="ant-border-space"
          bordered
        />
        {/* </div> */}
      </Row>
    </div>
  );
}

export default Withdrawal;
