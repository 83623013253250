import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getReports = async (values) => {
  console.log("params", values);
  // try {
  //   const data = await axios.get(
  //     `${process.env.REACT_APP_API}/v1/reports`,
  //     params,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem(
  //           process.env.REACT_APP_ACCESS_TOKEN
  //         )}`
  //       }
  //     }
  //   );
  //   return data;
  // } catch (error) {
  //   console.log(error.message);
  // }
};

const getEntry = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/reports/entry`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useReports(operation) {
  console.log("operation");
  return useQuery(["reportrequest"], () => getReports(operation), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useEntryFetchRequest() {
  return useQuery(["entryreports"], () => getEntry(), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}
