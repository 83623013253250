import { Button, Card, Col, Divider, Flex, Row } from "antd";
import Title from "antd/es/typography/Title";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const gridStyle = {
  width: "25%",
  textAlign: "center"
};

const Reports = () => {
  const navigate = useNavigate();
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Relatórios</Title>
      </Flex>
      <Divider />

      <Row gutter={[24, 2]} justify="center" span="4">
        <Col xs={24} xl={24} md={24}>
          <Card title="Card Title">
            <Card.Grid style={gridStyle}>
              <Button type="link">
                <Link to={"lista"}>Carteiras</Link>
              </Button>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Link to={"gerente"}>
                <Button type="link">Gerente</Button>
              </Link>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Button type="link">
                <Link to={"entry"}>Lançamento</Link>
              </Button>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
