import React, { useState } from "react";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useNavigate, useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Row,
  Statistic
} from "antd";
import { dateEndContract, daysToEndContract } from "../../helpers/dateHelper";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const PersonalInformations = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Informaçoes Pessoal</Title>
        <Button onClick={() => navigate(`editar`)}>Editar</Button>
      </Flex>
      <Divider />
      <Col md={24}>
        <Card bordered={true}>
          {investor?.start_of_contract ? (
            <Statistic
              title={`Tempo de contrato (${daysToEndContract(
                investor?.start_of_contract,
                dateEndContract(investor?.start_of_contract)
              )} dias restantes)`}
              value={investor?.start_of_contract}
              suffix={` - ${dateEndContract(investor?.start_of_contract)}`}
              groupSeparator=".."
            />
          ) : (
            ""
          )}
        </Card>
      </Col>
      <Divider />
      <Descriptions>
        <Descriptions.Item label="Documento" span={3}>
          {hiddenDocument ? (
            <>
              <EyeInvisibleOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              {investor?.document}{" "}
            </>
          ) : (
            <>
              {" "}
              <EyeOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              ******{" "}
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Nome">{investor?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{investor?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">
          {investor?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {investor?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions>
        <Descriptions.Item label="Pix">{investor?.pix}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default PersonalInformations;
