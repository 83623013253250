import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getPlans = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/v1/plan`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`
      }
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function usePlansFetchRequest() {
  return useQuery(["plansInvestors"], () => getPlans(), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}
