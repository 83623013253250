import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getNotifications = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/notifications-admin`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const getUserNotifications = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/notifications-admin/user-notification?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const postNotification = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/notifications-admin/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useNotificationsAdminFetchRequest() {
  return useQuery(["admin-notifications"], () => getNotifications(), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}
export function useUserNotificationsAdminFetchRequest(id) {
  return useQuery(
    ["admin-user-notifications"],
    () => getUserNotifications(id),
    {
      onSuccess: (data) => {
        return data.data.data;
      }
    }
  );
}

export function useNotificationPost(data) {
  return postNotification(data);
}
